import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from "../components/seo";

import ContactFooter from '../components/ContactForm/ContactFooter';

const AboutPage = () => {

  const data = useStaticQuery(graphql`
    query AboutMe {
      prismicAbout {
        data {
          titel {
            text
          }
          block {
            block_titel {
              text
            }
            block_items {
              text
            }
          }
          meta_description {
            text
          }
          hero_img {
            localFile {
              childImageSharp {
                fluid (maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      prismicBasicInfo {
        data {
          orange
          main_color_two
        }
      }
    }
  `);

  const { titel, block, hero_img, meta_description } = data.prismicAbout.data;
  const { orange, main_color_two } = data.prismicBasicInfo.data;

  return (
    <>
    <SEO title={titel.text} description={meta_description.text}/>

    <div className='borderradius-div-bottom' style={{backgroundColor: main_color_two}}>
      <section style={{maxWidth: 600, margin: '0 auto'}}>
        <Img fluid={hero_img.localFile.childImageSharp.fluid} />
      </section>

      <main style={{textAlign: 'center', paddingBottom: 55}}>
        <h1 className='resp-header'>{titel.text}</h1>
        {block.map((item, i) => (
          <section key={i} style={{
            margin: '50px auto',
            maxWidth: 400,
            textAlign: i%2===0 ? 'left' : 'right',
            paddingLeft: i%2===0 ? 100 : 60,
            paddingRight: i%2===0 ? 60 : 100
          }}>
            <h3 style={{color: orange}}>{item.block_titel.text}</h3>
            <span style={{fontSize: '90%'}}>{item.block_items.text}</span>
          </section>
        ))}
      </main>
      
    </div>

    <ContactFooter />

  </>
  )
}

export default AboutPage;